import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import MissionContext from "./MissionContext";
import AppButton from "../../../../global/elements/AppButton";
import UserNextActionsBanner from "./UserNextActionsBanner";
import {
  interestInviteStatus,
  paymentStatus,
  missionStatus,
  missionTypeConstant,
  PilotNextAction,
  projectStatus,
  packageStatusConstants,
} from "../../../../../lib/constants";
import firebase from "src/firebase";
import { getInterestedPilotIds } from "../../../../../services/interest-invite";
import theme from "src/theme";
import { DateTime } from "luxon";
import { isAdminMissionType } from "src/services/missions";

const PilotNextActions = ({
  packageLength = undefined,
  pilotPackages = [],
  projectDetails = undefined,
}) => {
  const actionData = useContext(MissionContext);
  const { mission, currentUser, addedInterst, interestExists, contract } =
    actionData || {};

  const latestPackageIndex = packageLength - 1;
  const [interestPilotCount, setInterestPilotCount] = useState(0);

  useEffect(() => {
    if (mission?.id && currentUser?.id) {
      getInterestedPilotIds(
        firebase,
        mission.id,
        interestInviteStatus.pilotRequested
      )
        .then((data) => {
          const count =
            data?.pilotIds?.filter((ele) => ele !== currentUser.id)?.length ||
            0;
          setInterestPilotCount(count);
        })
        .catch((e) => {
          console.log(e);
          setInterestPilotCount(0);
        });
    }
  }, [mission?.id, currentUser?.id]);

  const pilotUpload = useCallback(() => {
    isAdminMissionType(mission.missionType)
      ? actionData?.handleTabChange("e", 1 + packageLength)
      : actionData?.handleTabChange("e", 2 + packageLength);
  }, [actionData, packageLength]);

  const pilotSendInvoice = useCallback(() => {
    actionData?.handleTabChange("e", 1);
  }, [actionData]);

  const goToMissionPlanningTab = useCallback(() => {
    actionData?.handleTabChange("e", 2);
  }, [actionData]);

  const hasRejectedMission = (allPilotInfo, pilotId) => {
    const rejectedPilot = allPilotInfo.find(
      (pilot) => pilot.pilotId === pilotId
    );
    return rejectedPilot && rejectedPilot.status === "rejected";
  };
  const currentFlightDate =
    actionData?.missionPlanning?.data?.currentFlightDate;

  const getBannerState = useCallback(() => {
    let state = {
      actionText: "",
      actionSubText: "",
      actionSubText2: "",
      tabNumber: 2,
      background: undefined,
      bigText: false,
      button1: null,
      button2: null,
      redWarning: false,
      redWarningText: "",
    };

    if (mission) {
      if (
        (mission.status === missionStatus.pilotInvitedForBidding ||
          mission.status === missionStatus.biddingOngoing) &&
        mission.sentToPilots?.includes(currentUser.id) &&
        !hasRejectedMission(mission?.sentToPilotsInfo, currentUser?.id) &&
        mission.allowPilotBidding
      ) {
        state = {
          ...state,
          actionText: "Mission available !",
          actionSubText: PilotNextAction.submitBid,
          actionSubText2: (
            <>
              <Box>
                Date before Bidding Ends:
                {DateTime.fromJSDate(new Date(mission.biddingEndDate)).toFormat(
                  "dd/MM/yyyy"
                )}
              </Box>
              {!addedInterst?.price ? (
                <Box>No bid submitted yet</Box>
              ) : (
                <Box>Your Current bid: ${addedInterst?.price}</Box>
              )}
            </>
          ),
          button1: (
            <AppButton
              look="purple"
              label={interestExists ? "Change your price" : "Show interest"}
              onClick={() => actionData?.openShowInterest()}
              fullWidth
              noIcon
            />
          ),
          button2: (
            <AppButton
              look="inverted"
              label="Reject mission"
              onClick={() => actionData?.handlePilotDeclined()}
              fullWidth
              noIcon
            />
          ),
          redWarning: !mission.assignedPilot && interestPilotCount > 0,
          redWarningText: `${interestPilotCount} other pilot${
            interestPilotCount === 1 ? " has" : "s have"
          } applied to this mission`,
        };
      } else if (
        (mission.status === missionStatus.pilotInvitedForBidding ||
          mission.status === missionStatus.biddingOngoing) &&
        mission.sentToPilots?.includes(currentUser.id) &&
        !hasRejectedMission(mission?.sentToPilotsInfo, currentUser?.id) &&
        !mission.allowPilotBidding
      ) {
        state = {
          ...state,
          actionText: "Mission available !",
          actionSubText: PilotNextAction.submitInterest,
          actionSubText2: (
            <>
              <Box>
                Date before Interest Deadline ends:
                {DateTime.fromJSDate(new Date(mission.biddingEndDate)).toFormat(
                  "dd/MM/yyyy"
                )}
              </Box>
            </>
          ),
          button1: (
            <AppButton
              look="purple"
              label={"Show interest"}
              onClick={() => actionData?.openShowInterest()}
              fullWidth
              noIcon
            />
          ),
          button2: (
            <AppButton
              look="inverted"
              label="Reject mission"
              onClick={() => actionData?.handlePilotDeclined()}
              fullWidth
              noIcon
            />
          ),
          redWarning: !mission.assignedPilot && interestPilotCount > 0,
          redWarningText: `${interestPilotCount} other pilot${
            interestPilotCount === 1 ? " has" : "s have"
          } applied to this mission`,
        };
      }

      if (mission.status === missionStatus.biddingEnded) {
        state = {
          ...state,
          actionText: "Bidding Ended",
          actionSubText: PilotNextAction.biddingEnded,
          background: theme.palette.secondary.lightLavender,
        };
      }

      if (mission.assignedPilot === currentUser.id) {
        switch (mission.status) {
          case missionStatus.pilotSelected:
          case missionStatus.quoteSentToClient:
          case missionStatus.quoteDeclinedByClient:
          case missionStatus.quoteReissuedToClient:
            state = {
              ...state,
              actionText: "Congratulations ! You are Selected",
              actionSubText: PilotNextAction.selected,
              bigText: true,
            };
            break;

          case missionStatus.quoteAcceptedByClient:
            state = {
              ...state,
              actionText: "Your contract is ready",
              actionSubText: PilotNextAction.contract,
              button1: (
                <AppButton
                  look="purple"
                  label="Sign contract"
                  internalLink
                  url={`/app/my-profile/contracts/${contract?.id}`}
                  fullWidth
                  noIcon
                />
              ),
            };
            break;

          case missionStatus.pilotAssigned:
            state = {
              ...state,
              actionText: PilotNextAction.feasibility,
              button1: (
                <AppButton
                  look="purple"
                  label="Plan mission"
                  onClick={goToMissionPlanningTab}
                  fullWidth
                  noIcon
                />
              ),
              tabNumber: 2,
            };
            break;

          case missionStatus.missionNotFeasible:
            state = {
              ...state,
              actionText: "Waiting For Client Review",
              actionSubText: PilotNextAction.review,
              bigText: true,
            };
            break;

          case missionStatus.flyingDateSet:
          case missionStatus.rescheduled:
            state = {
              ...state,
              actionText: currentFlightDate
                ? `You are set to fly on: ${DateTime.fromJSDate(
                    new Date(
                      actionData?.missionPlanning?.data?.currentFlightDate
                    )
                  ).toFormat("dd/MM/yyyy")}`
                : "Date not set yet",
              button1: (
                <AppButton
                  look="purple"
                  label={PilotNextAction.reschedule}
                  onClick={goToMissionPlanningTab}
                  fullWidth
                  noIcon
                />
              ),
              tabNumber: 2,
            };
            break;

          case missionStatus.dataProcessing:
          case missionStatus.dataUploading:
            state = {
              ...state,
              actionText: PilotNextAction.uploadData,
              button1: (
                <AppButton
                  look="purple"
                  label="Upload your data"
                  onClick={pilotUpload}
                  fullWidth
                  noIcon
                />
              ),
              tabNumber: 2 + packageLength,
            };
            break;

          case missionStatus.sentForReviewToGlobhe:
          case missionStatus.sentForReviewToClient:
            if (
              pilotPackages[latestPackageIndex]?.packageStatusByAdmin ===
              packageStatusConstants.requestedChanges
            ) {
              state = {
                ...state,
                actionText: "Changes Requested by GLOBHE",
                bigText: true,
                actionSubText:
                  PilotNextAction.requestedChangesByAdmin +
                  pilotPackages[latestPackageIndex]?.requestedReason,
              };
              break;
            } else if (
              pilotPackages[latestPackageIndex]?.packageStatusByAdmin ===
              packageStatusConstants.rejected
            ) {
              state = {
                ...state,
                actionText: "Package Rejected, Under Globhe Review",
                bigText: true,
                actionSubText: PilotNextAction.packageHandleByAdmin,
              };
              break;
            }
            state = {
              ...state,
              actionText: PilotNextAction.approval,
            };
            break;

          case missionStatus.pilotPackageRejected:
          case missionStatus.dataRejectedByClient:
            state = {
              ...state,
              actionText: "Data Rejected !!",
              actionSubText: PilotNextAction.rejected,
              button1: (
                <AppButton
                  look="purple"
                  label="Upload your data"
                  onClick={pilotUpload}
                  fullWidth
                  noIcon
                />
              ),
              tabNumber: 2 + packageLength,
            };
            break;

          case missionStatus.dataAcceptedByClient:
            state = {
              ...state,
              actionText: "Data Accepted !!",
              actionSubText: "Please wait for Globhe to Complete the Order.",
            };
            break;

          case missionStatus.completed:
            if (mission.paymentStatusPilot === paymentStatus.paid) {
              state = {
                ...state,
                actionText: PilotNextAction.paid,
                bigText: true,
              };
            } else if (
              mission.paymentStatusPilot === paymentStatus.unpaid &&
              (mission.missionType === missionTypeConstant.clientOrder ||
                mission.allowInvoice === true)
            ) {
              state = {
                ...state,
                actionText: "Data approved. Send your invoice",
                actionSubText: PilotNextAction.bankDetails,
                button1: (
                  <AppButton
                    look="purple"
                    label="Approve your invoice"
                    onClick={pilotSendInvoice}
                    fullWidth
                    noIcon
                  />
                ),
                button2: (
                  <AppButton
                    look="inverted"
                    label="Update your bank details"
                    internalLink
                    url="/app/my-profile/bank-details"
                    fullWidth
                    noIcon
                  />
                ),
              };
            } else if (
              mission.missionType !== missionTypeConstant.clientOrder &&
              mission.allowInvoice === false
            ) {
              state = {
                ...state,
                actionText: "Data approved.",
                actionSubText: PilotNextAction.completed,
              };
            }
            break;

          default:
            return null;
        }
      }
    }

    if (projectDetails?.status) {
      switch (projectDetails.status) {
        case projectStatus.active:
          state = {
            ...state,
            actionText: "Active Project!",
            actionSubText: PilotNextAction.activeProject,
            bigText: true,
          };
          break;
        case projectStatus.completed:
          state = {
            ...state,
            actionText: "Completed Project!",
            actionSubText: PilotNextAction.completedProject,
          };
          break;
        case projectStatus.archived:
          state = {
            ...state,
            actionText: "Archived Project!",
            actionSubText: PilotNextAction.archivedProject,
          };
          break;
        default:
          return null;
      }
    }

    return state;
  }, [
    mission,
    currentUser?.id,
    addedInterst?.price,
    interestExists,
    actionData,
    interestPilotCount,
    contract?.id,
    goToMissionPlanningTab,
    packageLength,
    pilotUpload,
    pilotSendInvoice,
    projectDetails?.status,
    currentFlightDate,
    pilotPackages,
    latestPackageIndex,
  ]);

  const bannerState = useMemo(() => getBannerState(), [getBannerState]);

  if (!bannerState || !bannerState.actionText) return null;

  return (
    <Box>
      <UserNextActionsBanner
        actionText={bannerState.actionText}
        actionSubText={bannerState.actionSubText}
        actionSubText2={bannerState.actionSubText2}
        tabNumber={bannerState.tabNumber}
        background={bannerState.background}
        bigText={bannerState.bigText}
        button1={bannerState.button1}
        button2={bannerState.button2}
        redWarning={bannerState.redWarning}
        redWarningText={bannerState.redWarningText}
      />
    </Box>
  );
};

PilotNextActions.propTypes = {
  packageLength: PropTypes.number.isRequired,
  projectDetails: PropTypes.object.isRequired,
  pilotPackages: PropTypes.array.isRequired,
};

export default React.memo(PilotNextActions);
