import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Tooltip,
  CardContent,
  Grid,
} from "@mui/material";
import { Link } from "gatsby";
import useGetPilotsByIds from "../../../hooks/useGetPilotsByIds";
import {
  countryListAlpha2,
  missionStatus,
  missionListSource,
  contractWaiverLabels,
  missionTypeConstant,
  contractStatusLabel,
  activeMissionStatuses,
} from "../../../lib/constants";
import theme from "../../../theme";
import useGetDeclinedFeedback from "../../../hooks/useGetDeclinedFeedback";
const { DateTime } = require("luxon");
import defaultImage from "src/assets/images/profileImage.jpg";
import ReactCountryFlag from "react-country-flag";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  confirmPilotClicked,
  confirmSignedContractClicked,
} from "../../../services/pilots";
import StatusBadge from "../../global/elements/StatusBadge";
import Rating from "@mui/material/Rating";
import UniversalLoader from "src/components/global/elements/UniversalLoader";
import { useStateIfMounted } from "use-state-if-mounted";
import { updateMission } from "src/services/missions";
import { createNotification } from "src/services/notification";
import firebase from "src/firebase";
import AppButton from "src/components/global/elements/AppButton";
import { getMissionContract } from "src/services/contracts";
import SwitchSelect from "src/components/global/elements/SwitchSelect";

const style = {
  rejectPilot: {
    color: theme.palette.status.errorDark,
  },
  bold_info: {
    cursor: "pointer",
  },
  label: {
    lineHeight: "normal",
    color: theme.palette.black.darkSeaBlack,
    fontSize: "15px",
  },
  table_cell: {
    minWidth: 110,
    wordWrap: "break-word",
    padding: { md: 5 },
  },
  table_head: {
    display: "none",
  },
  link_row: {
    cursor: "pointer",
  },
  single_field_div: {
    display: "flex",
    padding: "15px",
    borderBottom: `1px solid ${theme.palette.grey.lightWarmGrey}`,
  },
  single_field_heading_div: {
    width: "40%",
  },
  single_field_heading: {
    color: theme.palette.black.coolGrey,
    float: "left",
    wordWrap: "break-word",
  },
  single_field_data_div: {
    width: "55%",
  },
  cursorChange: {
    cursor: "pointer",
  },
  avtarBox: {
    display: "flex",
  },
  avtarImage: {
    width: "50px",
    height: "50px",
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    "& img": {
      padding: "2px",
      borderRadius: "50%",
    },
  },
};

const PilotWithRating = ({ rating, avatar, name }) => (
  <Grid container alignItems="center" sx={style.avtarBox}>
    <Avatar
      alt="Operator's Avatar"
      src={avatar || defaultImage}
      sx={style.avtarImage}
    />
    <Grid container width="70%" flexDirection="column" marginLeft={1}>
      <Typography variant="body1">{name || "N/A"}</Typography>
      <Rating
        name="overallRating"
        value={rating || 0}
        precision={0.5}
        readOnly
        sx={{ fontSize: "1rem" }}
      />
    </Grid>
  </Grid>
);

PilotWithRating.propTypes = {
  rating: PropTypes.number,
  avatar: PropTypes.string,
  name: PropTypes.string,
};

const InvitedPilotsList = ({
  uninvitePilotHandler = null,
  unassignPilotHandler = null,
  source = null,
  submitMissionHandler = null,
  onEditingFinished = null,
  missionData = {},
  columnForPilot = [],
}) => {
  const [feedbacks] = useGetDeclinedFeedback(missionData?.id);
  const [isLoading, setIsLoading] = useState(false);

  const pilotsToShow =
    source === missionListSource.invite
      ? missionData.assignedPilot
        ? missionData.sentToPilots.filter(
            (id) => id !== missionData.assignedPilot
          )
        : missionData.sentToPilots || []
      : [missionData?.assignedPilot];

  const [pilots, loading] = useGetPilotsByIds(
    pilotsToShow,
    missionData?.id,
    source
  );
  const [invoice, setInvoice] = useStateIfMounted(
    missionData?.mayPilotEditInvoice
      ? { allowInvoice: true }
      : { allowInvoice: false }
  );
  const [waive, setWaive] = useStateIfMounted(
    missionData?.contractAcceptedViaAdmin === contractWaiverLabels.accepted
      ? { contractAcceptedViaAdmin: true }
      : { contractAcceptedViaAdmin: false }
  );

  const handleWaiveContract = (event) => {
    setWaive({ ...waive, [event.target.name]: event.target.checked });
    onSubmitMission();
  };
  const handleMayEditInvoice = (event) => {
    setInvoice({ ...invoice, [event.target.name]: event.target.checked });
    onSubmitEditInvoiceMission();
  };
  const onSubmitMission = () => {
    updateMission(firebase, missionData.id, {
      contractAcceptedViaAdmin: waive?.contractAcceptedViaAdmin
        ? contractWaiverLabels.notAccepted
        : contractWaiverLabels.accepted,
    })
      .then(() => {
        if (!waive?.contractAcceptedViaAdmin) {
          createNotification(
            firebase,
            missionData.assignedPilot,
            `Your contract has been waived.`,
            "Your contract has been waived.",
            `/app/missions/${missionData.id}`
          );
        }
        submitMissionHandler(true);
      })
      .catch((err) => submitMissionHandler(false, err.message))
      .finally(() => onEditingFinished());
  };
  const onSubmitEditInvoiceMission = () => {
    updateMission(firebase, missionData.id, {
      mayPilotEditInvoice: invoice.allowInvoice ? false : true,
    })
      .then(() => {
        submitMissionHandler(true);
      })
      .catch((err) => submitMissionHandler(false, err.message))
      .finally(onEditingFinished());
  };
  const onUnassignPilotClicked = async (pilot) => {
    setIsLoading(true);
    const pilotId = pilot.id;
    if (missionData?.missionType === missionTypeConstant.clientOrder) {
      let contract = await getMissionContract(pilotId, missionData?.id);
      if (contract?.status === contractStatusLabel.signed) {
        if (confirmSignedContractClicked()) unassignPilotHandler(pilot);
      } else {
        if (confirmPilotClicked("unassign")) unassignPilotHandler(pilot);
      }
    } else {
      if (confirmPilotClicked("unassign")) unassignPilotHandler(pilot);
    }
  };

  const getInvitedDate = (pilotId) => {
    if (!missionData?.sentToPilotsInfo?.length) return "";

    const pilotInfo = missionData.sentToPilotsInfo.find(
      (info) => info.pilotId === pilotId
    );

    return pilotInfo?.invitedDate
      ? DateTime.fromISO(pilotInfo.invitedDate).toISODate()
      : "";
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "date":
        return (
          <Typography variant="body1">
            {DateTime.fromISO(value.dateCreated).toISODate()}
          </Typography>
        );
      case "dateInvited":
        return (
          <Typography variant="body1">{getInvitedDate(value?.id)}</Typography>
        );
      case "array":
        return (
          <Typography variant="body1">
            {value.equipment?.length > 0 ? value.equipment.toString() : "n/a"}
          </Typography>
        );
      default:
        return null;
    }
  };

  const getCountryFlag = (countryName) => {
    const countryCode =
      Object.keys(countryListAlpha2).find(
        (key) => countryListAlpha2[key] === countryName
      ) || "SE";

    return (
      <ReactCountryFlag
        countryCode={countryCode}
        aria-label={countryName}
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
        cdnSuffix="svg"
        style={{
          position: "relative",
          top: "2px",
          fontSize: "22px",
          borderRadius: "100%",
        }}
        title={countryName}
      />
    );
  };

  const getStatus = (pilotId) => {
    if (missionData?.declinedByPilots?.includes(pilotId)) {
      return <StatusBadge name="Rejected" status="red" />;
    }
    return <StatusBadge name="Invited" status="yellow" />;
  };

  const onUninvitePilotClicked = async (id) => {
    if (confirmPilotClicked("uninvite")) {
      uninvitePilotHandler(id);
    }
  };

  const getDeclinedReason = (pilotId) => {
    if (!missionData?.declinedByPilots?.includes(pilotId)) {
      return "N/A";
    }

    const declinedFeedback = feedbacks?.find((f) => f.pilotId === pilotId);
    if (!declinedFeedback) return "N/A";

    let reasons = [];
    if (declinedFeedback?.declinedReason?.length) {
      reasons.push(declinedFeedback.declinedReason.join(", "));
    }

    if (declinedFeedback?.otherDeclinedReason) {
      reasons.push(declinedFeedback.otherDeclinedReason);
    }

    return reasons.length ? reasons.join(", ") : "N/A";
  };

  const renderCellContent = (column, row) => {
    const value = row[column.id] || "N/A";

    switch (column.field) {
      case "date":
        return valueConvertHandler("date", row);
      case "dateInvited":
        return valueConvertHandler("dateInvited", row);
      case "capital":
        return (
          <Typography style={style.bold_info} variant="body1">
            {value}
          </Typography>
        );
      case "status":
        return <Box>{getStatus(row.id)}</Box>;
      case "flag":
        return (
          <Box sx={style.avtarBox}>
            {getCountryFlag(value)}
            <Typography variant="body1" marginLeft={1}>
              {value}
            </Typography>
          </Box>
        );
      case "avtar":
        return (
          <Link
            to={row?.id ? `/app/pilots/${row.id}` : "#"}
            style={{ textDecoration: "none" }}
          >
            <Box sx={style.avtarBox}>
              <Avatar
                alt="Operator's avatar"
                src={row?.profileImageURL || defaultImage}
                sx={style.avtarImage}
              />
              <Typography variant="body1" marginTop={1} marginLeft={1}>
                {value}
              </Typography>
            </Box>
          </Link>
        );
      case "pilotWithRating":
        return (
          <Link
            to={
              row?.pilotId
                ? `/app/pilots/${row.pilotId}`
                : row.id
                ? `/app/pilots/${row.id}`
                : "#"
            }
            style={{ textDecoration: "none" }}
          >
            <PilotWithRating
              name={value}
              rating={row?.ratings?.overallRating}
              avatar={row?.profileImageURL}
            />
          </Link>
        );
      case "unassign":
        return (
          (missionData.status != missionStatus.completed ||
            !activeMissionStatuses.includes(missionData?.status)) && (
            <AppButton
              look="negative"
              noIcon
              small
              label="UnAssign"
              onClick={() => onUnassignPilotClicked(row)}
              submittingState={isLoading}
            />
          )
        );
      case "waiveButton":
        return missionData.status != missionStatus.completed ? (
          <SwitchSelect
            defaultChecked
            checked={waive.contractAcceptedViaAdmin}
            handleChange={handleWaiveContract}
            name="contractAcceptedViaAdmin"
          />
        ) : (
          <Typography variant="body1">
            {missionData.contractAcceptedViaAdmin}
          </Typography>
        );
      case "editInvoicebutton":
        return missionData.status != missionStatus.completed ? (
          <SwitchSelect
            defaultChecked
            checked={invoice.allowInvoice}
            handleChange={(e) => handleMayEditInvoice(e)}
            name="allowInvoice"
          />
        ) : (
          <Typography variant="body1">N/A</Typography>
        );
      case "reasonForRejection":
        return (
          <Typography variant="body1">{getDeclinedReason(row?.id)}</Typography>
        );
      default:
        return missionData.status !== missionStatus.completed ? (
          <Tooltip title="UnInvite pilot">
            <CancelIcon
              onClick={() => onUninvitePilotClicked(row.id)}
              sx={style.rejectPilot}
              fontSize="medium"
            />
          </Tooltip>
        ) : null;
    }
  };

  const renderMobileItem = (row) => (
    <Box
      sx={{
        margin: "15px 0px",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      }}
      key={`mobile-${row.id}`}
    >
      <CardContent>
        {columnForPilot?.map((column, key) => (
          <Box sx={style.single_field_div} key={`field-${key}`}>
            <Box sx={style.single_field_heading_div}>
              <Typography variant="h5" style={style.single_field_heading}>
                {column.label}
              </Typography>
            </Box>
            <Box sx={style.single_field_data_div}>
              <Box style={style.single_field_data}>
                {renderCellContent(column, row)}
              </Box>
            </Box>
          </Box>
        ))}
      </CardContent>
    </Box>
  );

  if (loading) {
    return (
      <Box>
        <UniversalLoader />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        margin: "auto",
        boxShadow: { xs: "none", sm: "0px 0px 4px rgba(0, 0, 0, 0.25)" },
        padding: { xs: 0, sm: "2%" },
        border: "none",
      }}
    >
      <TableContainer
        sx={{
          maxHeight: 620,
          display: { xs: "none", sm: "block" },
          borderColor: theme.palette.primary.pistachio,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnForPilot?.map((column, i) => (
                <TableCell
                  key={`header-${i}`}
                  align={column.align}
                  style={
                    column.sort
                      ? { ...style.cursorChange, ...style.table_cell }
                      : style.table_cell
                  }
                >
                  <Box sx={style.label}>{column.label}</Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pilots?.map((row) => (
              <TableRow
                key={`row-${row.id}`}
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{
                  "& > *": {
                    height: 120,
                    paddingTop: 3,
                    paddingBottom: 3,
                  },
                }}
                style={style.link_row}
              >
                {columnForPilot?.map((column, i) => (
                  <TableCell
                    sx={{ overflowWrap: "anywhere" }}
                    key={`cell-${i}`}
                    align={column.align}
                  >
                    {renderCellContent(column, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: { sm: "none" },
          padding: "5px",
        }}
      >
        {pilots?.map(renderMobileItem)}
      </Box>
    </Box>
  );
};

InvitedPilotsList.propTypes = {
  missionData: PropTypes.object.isRequired,
  uninvitePilotHandler: PropTypes.func,
  columnForPilot: PropTypes.array.isRequired,
  source: PropTypes.string,
  unassignPilotHandler: PropTypes.func,
  submitMissionHandler: PropTypes.func,
  onEditingFinished: PropTypes.func,
};

export default InvitedPilotsList;
