import React, { useContext } from "react";
import AppButton from "../../../../global/elements/AppButton";
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogContentText,
  Typography,
} from "@mui/material";
import MissionContext from "./MissionContext";
import theme from "../../../../../theme";
import {
  notificationType,
  pilotInterestWithPriceStr,
  pilotInterestWithoutPriceStr,
  pilotInviteType,
  interestInviteStatus,
} from "../../../../../lib/constants";
import { TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import firebase from "src/firebase";
import {
  createInterest,
  getInterest,
  updateInterest,
} from "../../../../../services/interest-invite";
import { createNotification } from "../../../../../services/notification";
import { getUser } from "../../../../../services/auth";
import { textFieldStyle } from "../../../../../lib/styleConstants";
import { useSnackbar } from "notistack";
import { getCurrentDateTime } from "../../../../../lib/helpers";

const styles = {
  inquiry: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    textAlign: "left",
    "& p": {
      marginBottom: "1em",
      color: theme.palette.grey[600],
    },
    marginBottom: "40px",
  },
  header: {
    textAlign: "center",
  },
  contentBox: {
    textAlign: "center",
  },
};

const InterestDialog = () => {
  const data = useContext(MissionContext);
  const { enqueueSnackbar } = useSnackbar();

  const interestUpdateMail = (data) => {
    const adminInterestUpdateMail = firebase
      .functions()
      .httpsCallable("adminInterestUpdateMail");
    adminInterestUpdateMail(data);
  };
  const pilotInterestUpdateMail = (data) => {
    const pilotInterestUpdateMail = firebase
      .functions()
      .httpsCallable("pilotInterestUpdateMail");
    pilotInterestUpdateMail(data);
  };

  const interstValidationSchema = Yup.object().shape({
    price: Yup.number().positive().typeError("Only numbers are allowed"),
  });

  var addInterestInitialValue = {
    price: null,
    id: null,
  };

  const addInterst = (values, { setSubmitting }) => {
    setSubmitting(true);
    const dataInfo = {
      mission: data?.mission,
      missionId: data?.mission?.id,
      missionName: data?.mission?.missionName,
      pilot: {
        id: data?.currentUser?.id,
        pilotName: data?.currentUser?.name || "",
        country: data?.currentUser?.location || "",
        email: data?.currentUser?.email || "",
        ratings: data?.currentUser?.ratings?.overallRating || 0,
        profileImageURL: data?.currentUser?.profileImageURL || "",
      },
    };
    if (data?.mission?.allowPilotBidding) {
      dataInfo.price = Number(values.price);
    }
    createInterest(firebase, dataInfo, "interest")
      .then(() => {
        enqueueSnackbar("Your interest added successfully", {
          variant: "success",
        });

        const dataToSend = {
          pilotName: data?.currentUser?.name,
          country: data?.currentUser?.location,
          missionId: data?.mission?.id,
          price: Number(values.price),
        };

        const pilotEmailData = {
          pilotName: data?.currentUser.name,
          missionId: data?.mission?.id,
          missionName: data?.mission?.missionName,
          pilotId: data?.currentUser?.id,
          locationLabel: data?.mission.locationLabel,
        };

        interestUpdateMail(dataToSend);
        pilotInterestUpdateMail(pilotEmailData);

        data?.setShowInterest(false);
        data?.fetchInterest();
        data?.refreshMission();
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
        console.log("Error", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const updateInterst = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const dataInfo = {
      pilot: {
        id: data?.currentUser.id,
        pilotName: data?.currentUser?.name || "",
        country: data?.currentUser?.location || "",
        email: data?.currentUser?.email || "",
        profileImageURL: data?.currentUser?.profileImageURL || "",
      },
    };

    if (data?.mission?.allowPilotBidding) {
      dataInfo.price = Number(values?.price);
    }

    let sentToPilotsInfo = [];
    data?.mission?.sentToPilotsInfo?.forEach((pilotInfo) => {
      if (pilotInfo.pilotId === data?.currentUser.id) {
        sentToPilotsInfo.push({
          invitedDate: pilotInfo.invitedDate,
          pilotId: pilotInfo.pilotId,
          responseDate: getCurrentDateTime(),
          adminResponseToAccept: pilotInfo?.adminResponseToAccept || null,
          initiallyRejected: pilotInfo.initiallyRejected || false,
          status: pilotInviteType.interestReceived,
        });
      } else {
        sentToPilotsInfo.push(pilotInfo);
      }
    });

    await firebase
      .firestore()
      .collection("missions")
      .doc(data?.mission?.id)
      .update({ sentToPilotsInfo: sentToPilotsInfo });

    const interest = await getInterest(
      firebase,
      data?.mission?.id,
      data?.currentUser.id
    );

    if (interest?.status === interestInviteStatus.adminApproved) {
      enqueueSnackbar(
        "You can't update your interest as it is already approved by admin",
        {
          variant: "error",
        }
      );
      setSubmitting(false);
      data?.setShowInterest(false);
      data?.refreshMission();
      return;
    }

    updateInterest(firebase, values.id, dataInfo)
      .then(() => {
        enqueueSnackbar("Your interest updated successfully", {
          variant: "success",
        });
        data?.setShowInterest(false);
        data?.fetchInterest();
        data?.refreshMission();
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
        console.log("Error", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (data?.interestExists) {
      values.id = data?.addedInterst?.id;
      updateInterst(values, { setSubmitting });
      createNotification(
        firebase,
        notificationType.adminNotification,
        `${
          getUser("userProfile")?.name
        } has updated his price on a mission named ${
          data?.mission.missionName
        }.`,
        `The mission price was updated by ${getUser("userProfile")?.name}`,
        `/app/missions/${data?.mission.id}`
      );
    } else {
      createNotification(
        firebase,
        notificationType.adminNotification,
        `${getUser("userProfile")?.name} has put his price on a mission named ${
          data?.mission?.missionName
        }.`,
        `A new price was added to a mission by ${getUser("userProfile")?.name}`,
        `/app/missions/${data?.mission?.id}`
      );
      addInterst(values, { setSubmitting });
    }
  };
  return (
    <>
      <Box sx={styles.inquiry}>
        {data?.showInterest && (
          <Dialog
            open={data?.showInterest}
            onClose={data?.onCloseInterestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="submit-packages-dialog-title"
              variant="h3"
              sx={styles.header}
            >
              {/* <Typography variant="h3" component="div">
                {data?.mission.missionName}
              </Typography> */}
              {/* {data?.interestExists ? "Edit your fee |" : "Enter your fee |"}{" "} */}
              {data?.interestExists
                ? "Update your daily price"
                : data?.mission.missionName}
            </DialogTitle>
            <DialogContent>
              <Formik
                initialValues={
                  data?.interestExists
                    ? data?.addedInterst
                    : addInterestInitialValue
                }
                validationSchema={
                  data?.mission.allowPilotBidding
                    ? interstValidationSchema
                    : null
                }
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => {
                  return (
                    <Form id="missionInterestForm">
                      <Grid
                        container
                        spacing={2}
                        marginTop={1}
                        marginBottom={1}
                      >
                        {data?.mission.allowPilotBidding && (
                          <>
                            <Grid item xs={12} md={2} />
                            <Grid item xs={12} md={5}>
                              <Typography variant="h6">
                                {data?.interestExists
                                  ? "You can adjust your price for this mission (USD)"
                                  : "Please set your preferred price for this mission in USD."}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Field
                                type="number"
                                name="price"
                                component={TextField}
                                placeholder="$"
                                required={data?.mission.allowPilotBidding}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                  ...textFieldStyle,
                                  "& input[type=number]": {
                                    MozAppearance: "textfield",
                                  },
                                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                    {
                                      WebkitAppearance: "none",
                                      margin: 0,
                                    },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={2} />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} mt={4}>
                        <DialogContentText
                          id="alert-dialog-description"
                          variant="body2"
                          sx={styles.contentBox}
                        >
                          {data?.mission.allowPilotBidding
                            ? pilotInterestWithPriceStr
                            : pilotInterestWithoutPriceStr}
                        </DialogContentText>
                      </Grid>
                      <Grid container marginTop={2} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <AppButton
                            look="inverted"
                            onClick={() => data?.setShowInterest(false)}
                            label="Cancel"
                            noIcon
                            disabled={isSubmitting}
                            addtionalStyle={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <AppButton
                            type="submit"
                            label="Let's go"
                            look="purple"
                            form="missionInterestForm"
                            submittingState={isSubmitting}
                            addtionalStyle={{ width: "100%" }}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={18} />
                            ) : (
                              "Confirm interest"
                            )}
                          </AppButton>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </>
  );
};

export default InterestDialog;
